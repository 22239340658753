import appConfig from "@/app.config";
export default {
  // Endpoints
  // customerList: 'customer/list',
  // submitMessage: 'message/submit',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: appConfig.tokenType,

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: appConfig.storageTokenKeyName,
  storageRefreshTokenKeyName: appConfig.storageRefreshTokenKeyName,
}
