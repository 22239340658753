import Vue from 'vue'
import axios from 'axios'
import appConfig from "@/app.config";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: appConfig.api.baseURL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
