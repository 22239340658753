import netConfig from './netwConfig'
import appConfig from "@/app.config";
export default class NetwService {
  // Will be used by this service for making API calls
  axiosIns = null

  endPoint = null

  // jwtConfig <= Will be used by this service
  netConfig = { ...netConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, netOverrideConfig) {
    this.axiosIns = axiosIns
    this.netConfig = { ...this.netConfig, ...netOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = accessToken
        }
        return config
      },
      error => Promise.reject(error),
    )

    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { response } = error
        if (response && response.status === 401) {
          // console.log("fire interceptor")
          window.location.href = "/login"
        }
        return Promise.reject(error)
      },
    )
  }

  getToken() {
    const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
    return `${appConfig.tokenType} ${user[appConfig.storageTokenKeyName]}`;
  }

  post(url, ...args) {
    /*
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: `${this.netConfig.tokenType} ${accessToken}`,
          }
        }
        return config
      },
      error => Promise.reject(error),
    )
    */
    return this.axiosIns.post(url, ...args)
  }

  get(url, ...args) {
    return this.axiosIns.get(url, ...args)
  }

  put(url, ...args) {
    return this.axiosIns.put(url, ...args)
  }

  delete(url, ...args) {
    return this.axiosIns.delete(url, ...args)
  }

  /*
  customerList(...args) {
    return this.axiosIns.get(this.netConfig.customerList, ...args)
  }

  submitSendMessage(...args) {
    return this.axiosIns.post(this.netConfig.submitMessage, ...args)
  }
  */
}
