<style>
ิh1,
h2,
h3,
h4 {
  color: black;
}
</style>
<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Buttons from '../ui/buttons.vue';
// import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "หน้าจออะไหล่",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    // Multiselect,
    // DatePicker,
  },
  data() {
    return {
      title: "หน้าจออะไหล่",
      items: [
        {
          text: "partMonitor",
        },
        {
          text: "หน้าจออะไหล่",
          active: true,
        },
      ],

      loading: undefined,
      form: {},
      list: [],
      timer: "",

      branchId: "",

      localDataBranch: [], //เป็น-array Id
      localData: [],
      DateSearch: "",
      rowSlot: [],
      rowStatusBoard: [],
      currentPage: 1,
      perPage: 8,
      reqDate: "",
      rowReq: [],

      h: window.innerHeight,
      w: window.innerWidth,

      stylewidth: "",
      result: [],
    };
  },
  computed: {},

  created() {
    this.getNow();
    this.getLocalData();
    this.getDataReq();
    // this.fetchEventsList();
    // this.timer = setInterval(this.fetchEventsList, 3000000);

    if (this.rowReq.length == 8) {
      this.stylewidth = `width:${this.w / this.perPage}px; height:${this.h -
        200}px;`;
    } else {
      this.stylewidth = `width:${this.w / this.rowReq.length}px; height:${this
        .h - 200}px;`;
    }

    // console.log(this.stylewidth);
  },

  methods: {
    fetchEventsList() {
      this.$http
        .get("events", (events) => {
          this.list = events;
          // console.log(events);
        })
        .bind(this);
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    getdataPartReq(reqId) {
      // this.loading = true;
      useNetw
        .get("api/part-requisition/parts", {
          params: {
            reqId: reqId,
          },
        })
        .then((response) => {
          const data = response.data.data;
          // // console.log('DataId = ',reqId , data);
          if (data.length > 0) {
            data.forEach((item) => {
              this.result.push(item);
            });
          }

          // console.log(this.result);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
      // return `${this.result.length}`
    },
    getDataReq: function() {
      // this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/part-requisition", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchIdUser,
            reqDate: this.reqDate,
            // reqDate: "2021-09-06",
            status: "SUBMIT",
          },
        })
        .then((response) => {
          this.rowReq = response.data.data;
          this.perPage = response.data.perPage;

          this.rowReq.forEach((item) => {
            this.getdataPartReq(item.reqId);
          });
          // // console.log("จออะไหล่", this.reqDate, "-", response.data.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      // // console.log(user);
    },

    // cancelAutoUpdate() {
    //   clearInterval(this.timer);
    // },

    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.reqDate = date;
      // // console.log(this.DateSearch);
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  // beforeDestroy() {
  //   this.cancelAutoUpdate();
  // },
  middleware: "authentication",
};
</script>

<template>
  <!-- <Layout> -->
  <!-- <PageHeader :title="title" :items="items" /> -->
  
  <div class="row">
    <head>
      <meta http-equiv="refresh" content="300">
    </head>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-skeleton-table
                :rows="5"
                :columns="6"
                :table-props="{ bordered: false, striped: true }"
                animation="throb"
              ></b-skeleton-table>
            </template>
            <div class="row">
              <div class="col-md-2">
                <router-link :to="{ name: 'home' }">
                  <a>กลับสู่หน้าหลัก</a>
                </router-link>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <th colspan="7" class="text-center">
                      <h1><b>หน้าจออะไหล่</b></h1>
                    </th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <div class="row " v-if="rowReq.length == 0">
              <div class="col-md-12 text-center">
                <h4>ไม่พบข้อมูลการเบิก</h4>
              </div>
            </div>
            <div class="row">
              <div
                class="col mx-0"
                v-for="(itemReq, index) in rowReq"
                :key="index"
              >
                <b-card
                  header-class="bg-transparent border-primary mx-0"
                  class="border border-primary"
                  :style="stylewidth"
                >
                  <template v-slot:header>
                    <h5 class="my-0 text-primary">
                      <i class="mdi mdi-bullseye-arrow me-3"></i>
                      <h3>
                        {{
                          itemReq.licensePlate == null
                            ? "-"
                            : itemReq.licensePlate
                        }}
                      </h3>
                      <hr />
                      {{ itemReq.reqCode }} <br />
                      ชื่อลูกค้า :
                      {{
                        itemReq.customerNameTh == null
                          ? ""
                          : itemReq.customerNameTh +
                            " " +
                            itemReq.customerFamilyNameTh
                      }}
                    </h5>
                  </template>
                  <h4 class="card-title mt-0">รายการอะไหล่</h4>
                  <div class="overflow-auto">
                    <div
                      v-for="(itemRS, index) in result.filter(
                        (item) => item.reqId === itemReq.reqId
                      )"
                      :key="index + 'Part'"
                    >
                      <div>
                        {{ index + 1 }}. [{{ itemRS.partCode }}] -
                        {{ itemRS.partNameTh }} <br />
                        <b>จำนวน : {{ itemRS.amount }}</b>
                      </div>
                    </div>
                  </div>
                  <p
                    v-if="
                      result.filter((item) => item.reqId === itemReq.reqId)
                        .length == 0
                    "
                  >
                    <b style="color:red">** ไม่พบข้อมูลอะไหล่ **</b>
                  </p>
                </b-card>
              </div>
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
  </div>

  <!-- </Layout> -->
</template>
